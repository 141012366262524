import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { School, SchoolOrganization } from "../../types"
import useGetSchools from "../Schools/hooks/useGetSchools"
import useGetSchoolOrganizations from "../Schools/hooks/useGetSchoolOrganizations"
import useCreateOrganization from "./hooks/useCreateOrganization"
import { logEvent } from "../../utils/analytics-service"

const CreateOrganizationScreen: FunctionComponent = () => {
  const navigate = useNavigate()

  const [selectedSchool, setSelectedSchool] = useState<School>()
  const [selectedSchoolOrganization, setSelectedSchoolOrganization] =
    useState<SchoolOrganization>()

  const { createOrganization, loading, error } = useCreateOrganization()

  // Schools
  const { getSchools, schools } = useGetSchools()
  const { getSchoolOrganizations, schoolOrganizations } =
    useGetSchoolOrganizations()

  useEffect(() => {
    getSchools()
    getSchoolOrganizations()
  }, [])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    location: Yup.string().optional(),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const body = {
        name: values.name,
        schoolId: selectedSchool?.id || null,
        schoolOrganizationId: selectedSchoolOrganization?.id || null,
      }

      const newOrganization = await createOrganization(
        body,
        handleCreateSuccess
      )

      if (newOrganization) {
        await localStorage.setItem(
          "activeOrganizationId",
          `${newOrganization.id}`
        )
        await localStorage.setItem(
          "activeOrganization",
          JSON.stringify(newOrganization)
        )
      }
    },
  })

  const handleCreateSuccess = async () => {
    logEvent("create_organization_success")
    toast.success("Successfully created organization")
    window.location.href = "/"
  }

  return (
    <ScreenContainer
      title="Create an organization"
      subtitle="Create a new organization to get started."
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        {error && <ErrorComponent error={error} />}
        {loading && <CircularProgress />}
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Organization Name"
          fullWidth
          variant="outlined"
          onChange={formik.handleChange}
          error={!!formik.errors.name}
          value={formik.values.name}
          sx={{ marginBottom: 2 }}
        />
        <Autocomplete
          key={selectedSchool ? `school-${selectedSchool.id}` : "emptySchool"}
          options={schools}
          value={selectedSchool}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setSelectedSchool(newValue as School)
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="School" variant="outlined" />
          )}
          sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}
        />
        <Autocomplete
          key={
            selectedSchoolOrganization
              ? `schoolOrganization-${selectedSchoolOrganization.id}`
              : "emptySchoolOrganization"
          }
          options={schoolOrganizations}
          value={selectedSchoolOrganization}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setSelectedSchoolOrganization(newValue as SchoolOrganization)
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Organization" variant="outlined" />
          )}
          sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}
        />
        <Button
          onClick={formik.submitForm}
          color="primary"
          variant="contained"
          disabled={loading}
          sx={{ textTransform: "none" }}
        >
          Create Organization
        </Button>
      </Box>
    </ScreenContainer>
  )
}

export default CreateOrganizationScreen
