import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { useParams } from "react-router-dom"
import styles from "../GuestLists/GuestListDetailsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import useGetAttendanceItemById from "./hooks/useGetAttendanceItemById"
import AttendanceMemberRow from "./components/AttendanceMemberRow"

type Params = {
  organizationId?: string
  attendanceItemId?: string
}

const AttendanceItemDetailsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const organizationId = Number(params.organizationId)
  const attendanceItemId = Number(params.attendanceItemId)
  localStorage.setItem("activeOrganizationId", `${organizationId}`)

  const navigate = useNavigate()

  const {
    getAttendanceItem,
    attendanceItem,
    loading: attendanceItemLoading,
    error: attendanceItemError,
  } = useGetAttendanceItemById()

  const [presentMembersCount, setPresentMembersCount] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      await getAttendanceItem(Number(organizationId), attendanceItemId)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (attendanceItem) {
      setPresentMembersCount(
        attendanceItem.members.filter((member) => member.present).length
      )
    }
  }, [attendanceItem])

  const updatePresentCount = (wasPresent: boolean, newPresent: boolean) => {
    setPresentMembersCount((prevCount) => prevCount + (newPresent ? 1 : -1))
  }

  if (attendanceItemLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!attendanceItem) {
    return (
      <ScreenContainer title={"Attendance item not found"}></ScreenContainer>
    )
  }

  return (
    <ScreenContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              marginRight: "8px",
            }}
            onClick={() =>
              navigate(`/organizations/${organizationId}/attendance`)
            }
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Box>
          <Box
            sx={{
              fontSize: "2rem",
              fontWeight: 600,
              margin: "1%",
              marginLeft: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {attendanceItem?.title}
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: "1rem",
            color: "gray",
            marginLeft: "25px",
            marginTop: "-20px",
            marginBottom: "15px",
          }}
        >
          {presentMembersCount} out of {attendanceItem.members.length} members
          present (
          {Math.round(
            (presentMembersCount / attendanceItem.members.length) * 100
          )}
          %)
        </Box>
      </Box>

      <Box>
        <Table component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Present</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceItem.members.map((member) => (
                <AttendanceMemberRow
                  key={member.id}
                  member={member}
                  attendanceItem={attendanceItem}
                  organizationId={organizationId}
                  updatePresentCount={updatePresentCount}
                />
              ))}
            </TableBody>
          </Table>
        </Table>
      </Box>
    </ScreenContainer>
  )
}

export default AttendanceItemDetailsScreen
