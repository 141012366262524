import { Box, Button, CircularProgress, TextField } from "@mui/material"
import React, { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import useJoinHouse from "./hooks/useJoinHouse"
import { logEvent } from "../../utils/analytics-service"

const JoinOrganizationScreen: FunctionComponent = () => {
  const navigate = useNavigate()

  const { joinHouse, loading, error, house } = useJoinHouse()

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const joinedHouse = await joinHouse(values.code, handleCreateSuccess)
      if (joinedHouse) {
        await localStorage.setItem("activeOrganizationId", `${joinedHouse.id}`)
        await localStorage.setItem(
          "activeOrganization",
          JSON.stringify(joinedHouse)
        )
      }
    },
  })

  const handleCreateSuccess = async () => {
    logEvent("join_organization_success")
    toast.success("Successfully joined organization")
    window.location.href = "/"
  }

  return (
    <ScreenContainer title="Join Existing Organization">
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          mb: "24px",
        }}
      >
        {error && <ErrorComponent error={error} />}
        {loading && <CircularProgress />}
        <TextField
          autoFocus
          required
          margin="dense"
          id="code"
          name="code"
          label="Invite Code"
          fullWidth
          variant="outlined"
          onChange={formik.handleChange}
          error={!!formik.errors.code}
          value={formik.values.code}
        />
        <Button
          onClick={formik.submitForm}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          Join Organization
        </Button>
      </Box>
    </ScreenContainer>
  )
}

export default JoinOrganizationScreen
