import React, { useState, useEffect } from "react"
import { TableRow, TableCell, Checkbox, Box, Avatar } from "@mui/material"
import toast from "react-hot-toast"
import useUpdateMemberAttendance from "../hooks/useUpdateMemberAttendance"
import { logEvent } from "../../../utils/analytics-service"

interface Member {
  id: number
  name: string
  present: boolean
  profile_photo: string
}

interface AttendanceItem {
  id: number
  title: string
  members: Member[]
}

interface Props {
  member: Member
  attendanceItem: AttendanceItem
  organizationId: number
  updatePresentCount: (wasPresent: boolean, newPresent: boolean) => void
}

const AttendanceMemberRow: React.FC<Props> = ({
  member,
  attendanceItem,
  organizationId,
  updatePresentCount,
}) => {
  const [checked, setChecked] = useState<boolean>(member.present)
  const { updateMemberAttendance, error: errorUpdatingMemberAttendance } =
    useUpdateMemberAttendance()

  useEffect(() => {
    setChecked(member.present)
  }, [member.present])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setChecked(isChecked)

    logEvent("update_member_attendance", {
      attendanceItemId: attendanceItem.id,
      memberId: member.id,
      present: isChecked,
    })

    updateMemberAttendance(
      Number(organizationId),
      attendanceItem.id,
      member.id,
      isChecked,
      () => {
        toast.success(
          `${member.name} successfully marked ${
            isChecked ? "present" : "absent"
          }`
        )
        updatePresentCount(!isChecked, isChecked) // Update count locally
      }
    )
  }

  return (
    <TableRow key={member.id}>
      <TableCell style={{ width: "25px" }}>
        <Checkbox checked={checked} onChange={handleCheckboxChange} />
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            alt={member.name}
            src={`${member.profile_photo}`}
            style={{ marginRight: 10 }}
          />
          {member.name}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default AttendanceMemberRow
