import { Box, Button, CircularProgress, Grid } from "@mui/material"
import React, { FunctionComponent, useEffect } from "react"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetMyHouses from "../Users/hooks/useGetMyHouses"
import { useNavigate } from "react-router-dom"
import { logEvent } from "../../utils/analytics-service"

const WelcomeScreen: FunctionComponent = () => {
  const { getMyHouses, loading, error } = useGetMyHouses()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userOrganizations = await getMyHouses()

        if (userOrganizations) {
          if (userOrganizations.length === 0) {
            // we're in the correct place
          }

          if (userOrganizations.length > 0) {
            localStorage.setItem(
              "activeOrganizationId",
              `${userOrganizations[0].id}`
            )
            localStorage.setItem(
              "activeOrganization",
              JSON.stringify(userOrganizations[0])
            )
            navigate("/")
          }
        }
      } catch (err) {
        console.error(`Error loading organizations: ${error}`)
      }
    }

    fetchData()

    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      title={"Welcome to Greek Chapter"}
      subtitle={
        "We're so happy to have you here! If you're wanting to set up your organization with Greek Chapter for the first time, click the first button below. If you are looking to join an existing organization on Greek Chapter, click the second button."
      }
    >
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "none", marginTop: "15px" }}
            onClick={() => {
              logEvent("onboarding_create_org_clicked")
              navigate("/organizations/create")
            }}
          >
            Create New Organization
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => {
              logEvent("onboarding_join_org_clicked")
              navigate("/organizations/join")
            }}
          >
            Join Existing Organization
          </Button>
        </Grid>
      </Grid>
    </ScreenContainer>
  )
}

export default WelcomeScreen
